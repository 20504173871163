<template>
  <div>
    <CCardBody class="c-card-body">
      <CDataTable
        :items="equipmentItems"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите код'}"
      >
        <template #code="{ item }">
          <td class="td">
            <router-link :to="`/equipment-items/${item.code}---${item.version}`">
              {{ item.code }}  v.{{ item.version }}
            </router-link>
          </td>
        </template>
        <template #archive="{ item }">
          <td class="td">
            <div v-if="item.archive">
                Архивный
            </div>
            <div v-else>
                Действующий
            </div>


          </td>
        </template>
        <template #description="{ item }">
          <td class="td col-md-9">
          {{ item.name }}
          </td>
        </template>
        <template #model_preview="{ item }">
          <td  class="td col-md-1">
            <img
              class="image-showcase"
              :src="item.model_preview"
              style="width: 100px"
            />
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.width-70 {
  width: 70%;
}

.item-right {
  text-align: right;
}

tr:nth-child(3) {
}
</style>

<script>
import { mapState, mapActions } from "vuex";

const fields = [
  {
    key: "code",
    label: "Код комплекса",
  },
  {
    key: "archive",
    label: "Статус",
  },
  {
    key: "name",
    label: "Название",
  },
  {
    key: "equipment_price",
    label: "Цена",
  },
  {
    key: "model_preview",
    label: "Превью",
  },
  {
    key: "weight",
    label: "Вес, кг",
  },
  {
    key: "equipment_price_zink",
    label: "Цена - цинк",
  },
  {
    key: "installation_percent",
    label: "% стоимости Монтажа",
  },
  {
    key: "installation_percent_concrete",
    label: "% стоимости Монтажа в бетон",
  },
];

export default {
  name: "EquipmentItems",
  data() {
    return {
      fields,
    };
  },
  computed: {
    ...mapState({
      equipmentItems: (state) => state.equipmentItems.all,
    }),
  },
  created() {
    this.getAll();
  },
  methods: {
    ...mapActions("equipmentItems", ["getAll"]),
  },
};
</script>
