var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCardBody',{staticClass:"c-card-body"},[_c('CDataTable',{attrs:{"items":_vm.equipmentItems,"fields":_vm.fields,"hover":"","border":"","sorter":"","tableFilter":{label: 'Поиск', placeholder: 'введите код'}},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[_c('router-link',{attrs:{"to":("/equipment-items/" + (item.code) + "---" + (item.version))}},[_vm._v(" "+_vm._s(item.code)+"  v."+_vm._s(item.version)+" ")])],1)]}},{key:"archive",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.archive)?_c('div',[_vm._v(" Архивный ")]):_c('div',[_vm._v(" Действующий ")])])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td col-md-9"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"model_preview",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td col-md-1"},[_c('img',{staticClass:"image-showcase",staticStyle:{"width":"100px"},attrs:{"src":item.model_preview}})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }